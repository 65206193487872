(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/context-menu/assets/javascripts/context-menu-item.js') >= 0) return;  svs.modules.push('/components/lb-ui/context-menu/assets/javascripts/context-menu-item.js');
"use strict";


const ContextMenuItem = _ref => {
  let {
    children,
    className,
    onClick
  } = _ref;
  return React.createElement("li", null, React.createElement("button", {
    className: "bg-white context-menu-item pointer padding-h-1 f-left".concat(className ? " ".concat(className) : ''),
    onClick: onClick,
    type: "button"
  }, children));
};
setGlobal('svs.components.lbUi.contextMenu.ContextMenuItem', ContextMenuItem);

 })(window);