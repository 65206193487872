(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/context-menu/assets/javascripts/context-menu.js') >= 0) return;  svs.modules.push('/components/lb-ui/context-menu/assets/javascripts/context-menu.js');
"use strict";


const ContextMenu = _ref => {
  let {
    children
  } = _ref;
  return React.createElement("ul", {
    className: "context-menu"
  }, children);
};
setGlobal('svs.components.lbUi.contextMenu.ContextMenu', ContextMenu);

 })(window);